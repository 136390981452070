import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"

import { FIND_PROPERTY } from "../../../graphql/development-graphql"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Section, Container } from "../../../components/grid/grid"
import { CardProject } from "../../../components/card-project/card-project"
import imgDummy from "../../../assets/img/img_development-main.jpg"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const WishForHomeLocationPage = ({location}) => {

    const staticData = useStaticQuery(graphql`
        query {
            imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            imgPropertyFinder: file(relativePath: { eq: "residence-finder.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            featured: file(relativePath: { eq: "img_development-main.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1125) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            wordPress {
                propertyType(id: "residential", idType: SLUG) {
                    name
                    description
                    acfPropertyType {
                        image {
                            sourceUrl
                        }
                        excerpt
                        banner {
                            sourceUrl
                        }
                    }
                    projects(first: 1000) {
                        nodes {
                            id
                            projectTags {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                            title
                            slug
                            excerpt
                            locations {
                                nodes {
                                    name
                                }
                            }
                            propertyTypes {
                                nodes {
                                    name
                                }
                            }
                            featuredImage {
                                sourceUrl
                                caption
                                mediaDetails {
                                    sizes {
                                        name
                                        width
                                        height
                                        file
                                        sourceUrl
                                    }
                                }
                            }
                        }
                    }
                }
                facilities(first: 100) {
                    nodes {
                        name
                        slug
                        facilityId
                        projects {
                            nodes {
                                propertyTypes {
                                    nodes {
                                        slug
                                        name
                                    }
                                }
                            }
                        }
                    }
                }

                locations(first: 100) {
                    nodes {
                        locationId
                        slug
                        name
                    }
                }

                propertyTypes {
                    nodes {
                        name
                        slug
                        propertyTypeId
                    }
                }
            }
        }
    `)

    const [findProperty, setFindProperty] = useState({
        location: null,
        propertyTypes: null,
        projects: null,
        checkBoxValues: [],
    })

    const [
        getProperty,
        { data: properties, loading: loadingProperties },
    ] = useLazyQuery(FIND_PROPERTY)

    const wordPress = staticData.wordPress.propertyType
    let featuredProperty
    wordPress.projects.nodes.map(item => {
        if ( item.projectTags.edges.length > 0 && item.projectTags.edges.find(x => x.node.name === "Featured Residence")) {
            featuredProperty = item
        }
        return -1
    })

    const locations = []
    staticData.wordPress.locations.nodes.map(item =>
        locations.push({ value: item.slug, label: item.name })
    )

    let propertyTypes = []
    staticData.wordPress.propertyTypes.nodes.map(item =>
        propertyTypes.push({ value: item.slug, label: item.name })
    )

    let listProperties = []
    properties &&
    properties.projects.edges.map(item => {
        listProperties.push({ label: item.node.title, value: item.node.slug })
    })
    const listPropertyTypes = staticData.wordPress.propertyTypes.nodes
    const listFacilities = staticData.wordPress.facilities.nodes
    const listLocations = staticData.wordPress.locations.nodes

    useEffect(() => {
        let facilities = []
        let locations = []
        let propertyTypeId =
        findProperty.propertyTypes &&
        listPropertyTypes.find(x => x.slug === findProperty.propertyTypes.value)
            .propertyTypeId

        findProperty.location &&
        listLocations.map(
            item =>
            item.name === findProperty.location.label &&
            locations.push(item.locationId)
        )

        findProperty.checkBoxValues.length > 0 &&
        findProperty.checkBoxValues.map(item => {
            listFacilities.map(facility => {
                if (facility.slug === item) {
                    facilities.push(facility.facilityId)
                }
            })
        })

        if (locations.length > 0 && propertyTypeId) {
            getProperty({
                variables: {
                    locationId: locations.length > 0 ? locations : null,
                    typeId: [propertyTypeId],
                    first: 1000,
                    facilityId: facilities.length > 0 ? facilities : null,
                },
            })
        }
        
    }, [findProperty])

    return(
        <Layout location={location} currentLocation={location.uri}>
            <SEO title="Wish For Home" />
            <Helmet>
                <body className="bd-page" />
            </Helmet>
            <CoverMain
                h1="Image Properti"
                // h1={data.wordPress.pageBy.title || "Image Properti"}
                img={
                (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
                staticData.imgCoverMain.childImageSharp.fluid
                }
                alt="Image Properti"
                className="cover-md"
            >
                <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
            </CoverMain>
            <Section>
                <Container className="container-md">
                    <h2>BSD City</h2>
                    Lorem ipsum dolor sit amet
                </Container>
            </Section>
            <Section className="py-4 d-none d-lg-block timeline-section">
                <div className={`nav-tabs-wrapper d-flex justify-content-center`} style={{borderBottom: "none"}}>
                    <ul class={`nav nav-tabs`} role="tablist">
                        <li className={`nav-item`}>
                            <div className={`nav-link active`} role="tab">
                                Properti
                            </div>
                        </li>
                        <li className={`nav-item`}>
                            <Link
                                className={`nav-link`}
                                role="tab"
                                to="/wishforhome/kavling"
                            >
                                Kavling
                            </Link>
                        </li>
                        <li className={`nav-item`}>
                            <Link
                                className={`nav-link`}
                                role="tab"
                                to="/wishforhome/faq"
                            >
                                FAQ
                            </Link>
                        </li>
                    </ul>
                </div>
                <Container>
                    <div className="row" style={{padding: '15px 0px'}}>
                            {wordPress &&wordPress.projects.nodes.map(item => {
                                return(
                                    <CardProject
                                        key={item.title}
                                        title={item.title}
                                        description={
                                            item.excerpt ||
                                            "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                                        }
                                        img={
                                            item.featuredImage
                                            ? item.featuredImage.sourceUrl
                                            : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
                                        }
                                        // area="6000 hectares"
                                        // location={
                                        //     item.locations &&
                                        //     item.locations.nodes.length > 0 &&
                                        //     item.locations.nodes[0].name
                                        // }
                                        // area={item.townshipInformation.hectares}
                                        link={`/wishforhome/${item.slug}`}
                                        className="ratio-2-1 col-md-4 p-1"
                                    />
                                )
                            })}
                    </div>
                </Container>
                <Container>
                    <div className="row" style={{padding: '15px 0px'}}>
                        <div className="col-md-4" style={{maxHeight: '100%'}}>
                            <div className="imgProject">
                                <Link to="#">
                                    <img src={imgDummy}  style={{height:'100%', width:'100%'}}/>
                                </Link>
                            </div>
                            <div className="titleProject" style={{position:'absolute', display:'block', bottom:'10px', left:'30px', color:'#fff', fontWeight:'bold'}}>
                                <Link to="#" style={{color:'#fff'}}>
                                    Akasa
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4" style={{maxHeight: '100%'}}>
                            <div className="imgProject">
                                <Link to="#">
                                    <img src={imgDummy}  style={{height:'100%', width:'100%'}}/>
                                </Link>
                            </div>
                            <div className="titleProject" style={{position:'absolute', display:'block', bottom:'10px', left:'30px', color:'#fff', fontWeight:'bold'}}>
                                <Link to="#" style={{color:'#fff'}}>
                                    Alesha
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4" style={{maxHeight: '100%'}}>
                            <div className="imgProject">
                                <Link to="#">
                                    <img src={imgDummy}  style={{height:'100%', width:'100%'}}/>
                                </Link>
                            </div>
                            <div className="titleProject" style={{position:'absolute', display:'block', bottom:'10px', left:'30px', color:'#fff', fontWeight:'bold'}}>
                                <Link to="#" style={{color:'#fff'}}>
                                    Assati
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{padding: '15px 0px'}}>
                        <div className="col-md-4" style={{maxHeight: '100%'}}>
                            <div className="imgProject">
                                <Link to="#">
                                    <img src={imgDummy}  style={{height:'100%', width:'100%'}}/>
                                </Link>
                            </div>
                            <div className="titleProject" style={{position:'absolute', display:'block', bottom:'10px', left:'30px', color:'#fff', fontWeight:'bold'}}>
                                <Link to="#" style={{color:'#fff'}}>
                                    Akasa
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4" style={{maxHeight: '100%'}}>
                            <div className="imgProject">
                                <Link to="#">
                                    <img src={imgDummy}  style={{height:'100%', width:'100%'}}/>
                                </Link>
                            </div>
                            <div className="titleProject" style={{position:'absolute', display:'block', bottom:'10px', left:'30px', color:'#fff', fontWeight:'bold'}}>
                                <Link to="#" style={{color:'#fff'}}>
                                    Alesha
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4" style={{maxHeight: '100%'}}>
                            <div className="imgProject">
                                <Link to="#">
                                    <img src={imgDummy}  style={{height:'100%', width:'100%'}}/>
                                </Link>
                            </div>
                            <div className="titleProject" style={{position:'absolute', display:'block', bottom:'10px', left:'30px', color:'#fff', fontWeight:'bold'}}>
                                <Link to="#" style={{color:'#fff'}}>
                                    Assati
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>
        </Layout>
    )
}
export default WishForHomeLocationPage